<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div
        aria-hidden="true"
        class="fixed inset-0 transition-opacity"
        @click="closeModal"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        aria-hidden="true"
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        >&#8203;</span
      >
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        aria-labelledby="modal-headline"
        aria-modal="true"
        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
        role="dialog"
      >
        <div>
          <div class="mt-3 sm:mt-5">
            <h3
              id="modal-headline"
              class="text-lg leading-6 font-medium text-gray-900"
            >
              1. POLITICALLY EXPOSED PERSON
            </h3>
            <div class="mt-2 mb-4">
              <p class="text-sm text-gray-500">
                The meaning ‘Politically Exposed Persons’ includes the following
                natural persons who are or have been entrusted with prominent
                public functions’ in any country:
              </p>
              <ul class="list-disc text-sm text-gray-500 pl-4">
                <li>
                  Heads of State, heads of government, ministers and deputy or
                  assistant ministers;
                </li>
                <li>Members of parliaments;</li>
                <li>
                  Members of supreme courts, of constitutional courts or of
                  other high-level judicial bodies whose decisions are not
                  subject to further appeal, except in exceptional
                  circumstances;
                </li>
                <li>
                  Members of courts of auditors or of the boards of central
                  banks;
                </li>
                <li>
                  Ambassadors, chargιs d'affaires and high-ranking officers in
                  the armed forces;
                </li>
                <li>
                  Members of the administrative, management or supervisory
                  bodies of State-owned enterprises. None of the categories set
                  out above should be understood as covering middle ranking or
                  more junior officials. Further, where a person has ceased to
                  be entrusted with a prominent public function within the
                  meaning of the above definition for a period of at least one
                  year, such persons shall not be considered a Politically
                  Exposed Person.
                </li>
              </ul>
            </div>
            <h3
              id="modal-headline"
              class="text-lg leading-6 font-medium text-gray-900"
            >
              2. IMMEDIATE FAMILY OR CLOSE ASSOCIATES
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                Politically Exposed Persons are also the immediate family
                members of such persons as set out under Definition 1, which
                means:
              </p>
              <ul class="list-disc text-sm text-gray-500 pl-4">
                <li>
                  The spouse or the person with which cohabit for at least one
                  year;
                </li>
                <li>
                  The children and their spouses or the persons with which
                  cohabit for at least one year;
                </li>
                <li>
                  The parents. Politically exposed persons are also persons
                  known to be close associates of such persons as set out under
                  Definition 1, which means:
                </li>
                <li>
                  Any natural person who is known to have joint beneficial
                  ownership of legal entities or legal arrangements, or any
                  other close business relations, with a person referred to in
                  Definition 1;
                </li>
                <li>
                  Any natural person who has sole beneficial ownership of a
                  legal entity or legal arrangement which is known to have been
                  set up for the benefit de facto of the person referred to in
                  Definition 1.
                </li>
              </ul>
            </div>
            <div class="mt-5 sm:mt-6">
              <a
                href="#"
                @click="closeModal"
                type="button"
                class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              >
                {{ $t("Ok") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PoliticallyExposedModal",
  methods: {
    closeModal() {
      this.$emit("closeModal", "false");
    },
  },
};
</script>