<template>
  <div v-if="whitelabel !== 'OrbitInvest'" class="m-3 mt-5">
    <div
      v-if="whitelabel !== 'GCCBrokers'"
      class="flex-col max-w-screen-xl mx-auto py-10 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8"
    >
      <a v-if="whitelabel === 'EagleGlobalMarkets'" class="self-start">
        <img
          :src="require(`../assets/EagleGlobalMarkets-logo.svg`)"
          alt="logo"
          class="md:block h-auto w-32"
        />
      </a>
      <span
        :class="whitelabel === 'TD365' ? '' : 'text-center'"
        class="mt-1 text-sm leading-5 text-gray-500"
      >
        <span
          v-if="
            whitelabel === 'TD365' ||
            (whitelabel === 'TDSouthAfrica' && this.$route.path.includes('ib'))
          "
          class="font-bold"
        >
          Disclaimer:
        </span>
        <span v-else-if="whitelabel === 'Blackstone'" class="font-bold">
          High Risk Investment Warning:
        </span>
        <span v-else v-html="$t('risk-warning')" />
        <!-- this replace function is replacing URLs %1 %2 %3 %4 in show_footer_text with the values in data "url" -->
        <span
          v-if="
            whitelabel === 'TD365' ||
            whitelabel === 'TDSouthAfrica' ||
            whitelabel === 'Blackstone'
          "
          class="ml-1"
          v-html="
            $t(show_footer_text).replace(/%(\d+)/g, (_, n) => url[+n - 1])
          "
        />
        <span
          v-else
          class="ml-1 footer-text-container"
          v-html="$t(show_footer_text)"
        />
      </span>
      <div v-if="whitelabel === 'ICMCapital'">
        <br />
        <span class="mt-1 text-center text-sm leading-5 text-gray-500">
          ICM Capital Limited, Level 17, Dashwood House, 69 Old Broad Street,
          London EC2M 1QS, United Kingdom
        </span>
      </div>
    </div>

    <div
      v-if="whitelabel === 'GCCBrokers'"
      class="flex-col max-w-screen-xl mx-auto py-10 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8"
    >
      <span class="mt-1 text-center text-sm leading-5 text-gray-500">
        {{
          $t(
            "gcc-brokers-limited-is-regulated-by-the-financial-services-commission-mauritius-under-registration-number"
          )
        }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "Sidebar",
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
      url: [
        "https://tradedirect365.com.au/",
        "https://td365.com/td365-com-offer/",
        "https://td365.com/terms",
        "https://tradedirect365.com.au/wp-content/cache/all/terms/index.html",
        "https://blackstonefutures.co.za/risk-disclosure-notice/",
        "https://blackstonefutures.co.za/",
        "https://blackstonefutures.co.za/privacy-policy/",
        "https://blackstonefutures.co.za/disclaimer/",
        "https://blackstonefutures.co.za/customer-agreement/",
        "https://blackstonefutures.co.za/risk-disclosure-notice/",
        "https://blackstonefutures.co.za/complaints-procedure/",
      ],
    };
  },
  computed: {
    show_footer_text() {
      switch (this.whitelabel) {
        case "ICMTrader":
          return this.$sanitize(
            "cfds-are-leveraged-products-and-carry-a-high-level-of-risk-icm-trader"
          );
          break;
        case "ICMVC":
          return this.$sanitize(
            "cfds-and-spot-fx-are-leveraged-products-trading-cfds-or-spot-fx-carries-a-high-risk-to-your-capital-"
          );
          break;
        case "ICMCapital":
          return this.$sanitize(
            "cfds-and-spot-fx-are-complex-instruments-and-come-with-a-high-risk-of-losing-money-rapidly-due-to-leverage"
          );
          break;
        case "ICMMENA":
          return this.$sanitize(
            "cfds-and-spot-fx-are-complex-instruments-and-come-with-a-high-risk-of-losing-money-rapidly-due-to-leverage"
          );
          break;
        case "ICMEU":
          return this.$sanitize(
            "cfds-are-complex-instruments-and-come-with-a-high-risk-of-losing-money-rapidly-due-to-leverage-it-wa"
          );
          break;
        case "ICMMU":
          // if its IB
          if (this.$route.path.includes("ib")) {
            return this.$sanitize(
              "cfds-are-leveraged-products-and-carry-a-high-level-of-risk-which-means-you-can-lose-more-than-your-i"
            );
          } else {
            return this.$sanitize(
              "cfds-and-spot-fx-are-leveraged-products-trading-cfds-or-spot-fx-carries-a-high-risk-to-your-capital-"
            );
          }
          break;
        case "Promena":
          return this.$sanitize(
            "cfds-are-leveraged-products-and-carry-a-high-level-of-risk-which-means-you-can-lose-more-than-your-i"
          );
          break;
        case "TDSouthAfrica":
          if (this.$route.path.includes("ib")) {
            return `CFD trading is not suitable for all investors. CFDs are leveraged products and carry a high level of risk which means you can lose more than your initial deposit and you do not own or have any interest in the underlying asset. TD365 is a trading name of Finsa Pty Limited and does not issue advice, recommendations or opinions in relation to acquiring, holding or disposing of a CFD. TD365 is not a financial adviser and all services are provided on an execution only basis.
                  <br><br>
                  The information on our website is for general informational purposes and does not take into account your objectives, financial situation or needs. We recommend that you seek independent professional advice and consider our Product Disclosure Statement, Financial Services Guide and Client Agreement, by <a class="text-indigo-600 cursor-pointer" target="_blank" href="%4">clicking here</a> , before you enter into any transaction with us.
                  <br><br>
                  The information on this site is not directed at residents of the United States or any particular country outside Australia and is not intended for distribution to, or use by, any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation
                  <br><br>
                  TradeDirect365 Pty Ltd is a trading name of Finsa Pty Limited (ACN 158 065 635; AFSL No. 422661).
                  <br><br>
                  <hr>
                  <br>
                  Copyright © 2018. TradeDirect365 Pty Ltd. ACN 162520149. All Rights Reserved.`;
          } else {
            return `Financial Spread Trades are complex instruments and come with a high risk of losing money rapidly due to leverage. 
            Trading leveraged products does not give you ownership of the underlying asset. 
            You should consider whether you understand how spread trading works and whether you can afford to take the high risk of losing your capital. 
            TD365 is a registered business name of Trade Nation Ltd (Registration Number 203493 B), 
            which is authorised and regulated by the Securities Commission of the Bahamas, SIA-F216. As such TD365 renders financial services to clients on behalf of Trade Nation Ltd. 
            TD365 is not a financial adviser and all services are provided on an execution only basis. 
            The information on our website is for general informational purposes and does not take into account your objectives, financial situation or needs.
            <br>
            <br>
            We recommend that you seek independent professional advice and consider our <a class="text-indigo-600 cursor-pointer" href="%2" target="_blank">Client Agreement</a> and Risk Warning Notice,
            by clicking <a class="text-indigo-600 cursor-pointer" href="%3" target="_blank">here</a>, before you enter into any transaction with us. 
            The information on this site is not directed at residents of the United States or any particular country outside of The Bahamas and is not intended for distribution to, 
            or use by, any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation.`;
          }
          break;
        case "TD365":
          return `CFD trading is not suitable for all investors. CFDs are leveraged products and carry a high level of risk which means you can lose more than your initial deposit and you do not own or have any interest in the underlying asset. TD365 is a trading name of Finsa Pty Limited and does not issue advice, recommendations or opinions in relation to acquiring, holding or disposing of a CFD. TD365 is not a financial adviser and all services are provided on an execution only basis.
              <br>
              <br>
              The information on our website is for general informational purposes and does not take into account your objectives, financial situation or needs. We recommend that you seek independent professional advice and consider our Product Disclosure Statement, Financial Services Guide and Client Agreement, by
              <a class="text-indigo-600 cursor-pointer" target="_blank" href="%1">clicking here</a>
              , before you enter into any transaction with us.
              <br>
              <br>
              The information on this site is not directed at residents of the United States or any particular country outside Australia and is not intended for distribution to, or use by, any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation\n
              <br>
              <br>
              TradeDirect365 Pty Ltd is a trading name of Finsa Pty Limited (ACN 158 065 635; AFSL No. 422661).`;
          break;
        case "ICMAP":
          if (this.$route.path.includes("ib")) {
            return this.$sanitize(
              "cfds-are-leveraged-products-and-carry-a-high-level-of-risk-which-means-you-can-lose-more-than-your-i"
            );
          } else {
            return this.$sanitize(
              "cfds-and-spot-fx-are-leveraged-products-trading-cfds-or-spot-fx-carries-a-high-risk-to-your-capital-"
            );
          }
          break;
        case "Blackstone":
          if (this.$route.path.includes("ib")) {
            return `Trading foreign exchange and/or contracts for difference on margin carries a high level of risk, and may not be suitable for all investors. The possibility exists that you could sustain a loss in excess of your deposited funds and therefore, you should not speculate with capital that you cannot afford to lose. Before deciding to trade the products offered by BlackStone Futures you should carefully consider your objectives, financial situation, needs and level of experience. You should be aware of all the risks associated with trading on margin. BlackStone Futures provides general advice that does not take into account your objectives, financial situation or needs. The content of this Website must not be construed as personal advice. BlackStone Futures recommends you seek advice from a separate financial advisor. Please click here to read full <a class="text-indigo-600 cursor-pointer" target="_blank" href="%5">risk warning</a>.
          <br>
          <br> 
          <span class="font-bold">Risk Disclosure Notice&colon;</span> You should assume that BlackStone Futures telephone lines are recorded (although this is not guaranteed) and shall remain exclusive property of BlackStone Futures which constitutes as evidence of the instructions you have given and may be used for purposes by us, including as evidence in any dispute. You should keep a written timed and dated record of all dealings with BlackStone Futures. BlackStone Futures is bound by the Data Protection Act. For more information, please <a class="text-indigo-600 cursor-pointer" target="_blank" href="%6">contact us</a>.
          <br>
          <br> 
          <span class="font-bold">Regulation&colon;</span> BlackStone Futures is authorised and regulated by the Financial Services Board (FSB). Registration number 38782.
          <br>
          <br> 
          <a class="text-indigo-600 cursor-pointer" target="_blank" href="%7">Privacy Policy</a>, 
          <a class="text-indigo-600 cursor-pointer" target="_blank" href="%8">Customer Agreement Disclaimer<a>, 
          <a class="text-indigo-600 cursor-pointer" target="_blank" href="%9">Business Terms</a>, 
          <a class="text-indigo-600 cursor-pointer" target="_blank" href="%10">Risk Disclosure</a>, 
          <a class="text-indigo-600 cursor-pointer" target="_blank" href="%11">Complaints Procededure</a>, 
          <a class="text-indigo-600 cursor-pointer" target="_blank" href="%6">Market Information</a>`;
          } else {
            return `Trading foreign exchange and/or contracts for difference on margin carries a high level of risk, and may not be suitable for all investors. The possibility exists that you could sustain a loss in excess of your deposited funds and therefore, you should not speculate with capital that you cannot afford to lose. Before deciding to trade the products offered by BlackStone Futures you should carefully consider your objectives, financial situation, needs and level of experience. You should be aware of all the risks associated with trading on margin. BlackStone Futures provides general advice that does not take into account your objectives, financial situation or needs. The content of this Website must not be construed as personal advice. BlackStone Futures recommends you seek advice from a separate financial advisor. Please click here to read full <a class="text-indigo-600 cursor-pointer" target="_blank" href="%5">risk warning</a>.
          <br>
          <br> 
          <span class="font-bold">Risk Disclosure Notice&colon;</span> You should assume that BlackStone Futures telephone lines are recorded (although this is not guaranteed) and shall remain exclusive property of BlackStone Futures which constitutes as evidence of the instructions you have given and may be used for purposes by us, including as evidence in any dispute. You should keep a written timed and dated record of all dealings with BlackStone Futures. BlackStone Futures is bound by the Data Protection Act. For more information, please <a class="text-indigo-600 cursor-pointer" target="_blank" href="%6">contact us</a>.
          <br>
          <br> 
          <span class="font-bold">Regulation&colon;</span>BlackStone Futures (Pty) Limited (Registration Number 2009/008512/07) is a duly appointed juristic representative of Trade Nation (Pty) Limited, (Registration Number 2018/418755/07) which is an authorised Financial Services Provider under the Financial Advisory and Intermediary Services Act No 37 of 2002 – FSP#49846. As such BlackStone Futures (Pty) Limited renders financial services to clients on behalf of Trade Nation (Pty) Limited.
          <br>
          <br> 
          <a class="text-indigo-600 cursor-pointer" target="_blank" href="%7">Privacy Policy</a>, 
          <a class="text-indigo-600 cursor-pointer" target="_blank" href="%8">Customer Agreement Disclaimer<a>, 
          <a class="text-indigo-600 cursor-pointer" target="_blank" href="%9">Business Terms</a>, 
          <a class="text-indigo-600 cursor-pointer" target="_blank" href="%10">Risk Disclosure</a>, 
          <a class="text-indigo-600 cursor-pointer" target="_blank" href="%11">Complaints Procededure</a>, 
          <a class="text-indigo-600 cursor-pointer" target="_blank" href="%6">Market Information</a>`;
          }
        case "OneTradingMarkets":
          if (this.$route.path.includes("ib")) {
            return "cfds-are-leveraged-products-and-carry-a-high-level-of-risk-onetradingmarkets";
          } else {
            return "cfds-are-leveraged-products-and-carry-a-high-level-of-risk";
          }
        case "EagleGlobalMarkets":
          return "Investing in Eagle Global Markets derivative products carries significant risks and is not suitable for all investors. You could lose more than your deposits. You do not own, or have any interest in, the underlying assets. We recommend that you seek independent advice and ensure you fully understand the risks involved before trading. Spreads may widen depending on liquidity and market volatility. The information on this website is prepared without considering your objectives, financial situation or needs. Consequently, you should consider the information in light of your objectives, financial situation and needs.";
        default:
          return "cfds-and-spot-fx-are-leveraged-products-trading-cfds-or-spot-fx-carries-a-high-risk-to-your-capital-";
          break;
      }
    },
  },
};
</script>
